import {Button, Text} from "@mantine/core";
import { HomeSendMessageForm } from "features/homes/components/home.send-message.modal/home.send-message.form";
import { useModals } from "@mantine/modals";
import {Send} from "tabler-icons-react";
import { showNotification } from "@mantine/notifications"

interface Props {
    onSubmitForm:(values:any) => void,
}

export function HomeSendMessageButton({ onSubmitForm }:Props) {
    const modals = useModals();

    async function _onSubmitForm(values:any){
        await onSubmitForm(values);
        modals.closeAll();
        showNotification({
            title: "Message sent!",
            message: "Your concierge will get back to you soon",
            color: "green",
            autoClose: 1000 * 10,
        })
    }

    const renderHomeForm = () => {
        modals.openModal({
            withCloseButton:true,
            closeOnClickOutside:true,
            closeOnEscape:true,
            size:500,
            overflow:'outside',
            title: (
                <Text size={'xl'}>Send Message</Text>
            ),
            children: (
                <HomeSendMessageForm onSubmitQuery={_onSubmitForm}/>
            ),
        })
        return false;
    }

    return (
        <Button leftIcon={<Send size={18}/>} onClick={renderHomeForm}>
            Send Message
        </Button>
    )
}
