import {ModelRoutes, ModelApiService} from "services/strapi"
import {
    IHome,
    IHomeEstimatedMarketValue,
    IHomeListing,
    IHomePricingModel,
    IHomeRecentlySoldNear,
    IHomeSellScore,
} from "features/homes"
import {strapi} from "lib/strapi-api"
import axios from "axios"
import {ETaskFormTypes} from "features/tasks"

class HomeApiSingleton extends ModelApiService<IHome> {
    async getSellScore(homeId: number): Promise<IHomeSellScore | undefined> {
        try {
            const res = await strapi.get(`/homes/${homeId}/sell-score`)
            return res.data as IHomeSellScore
        } catch (e) {
            if (axios.isAxiosError(e)) {
                console.error(`[SellScore] Error in route: ${this.route}`, e.message)
            }
            return undefined
        }
    }

    async getEstimatedMarketValue(
        homeId: number
    ): Promise<IHomeEstimatedMarketValue | undefined> {
        try {
            const res = await strapi.get(`/homes/${homeId}/estimated-market-value`)
            return res.data as IHomeEstimatedMarketValue
        } catch (e) {
            if (axios.isAxiosError(e)) {
                console.error(
                    `[EstimatedMarketValue] Error in route: ${this.route}`,
                    e.message
                )
            }
            return undefined
        }
    }

    async requestAvm(
        homeId?: number
    ): Promise<boolean | undefined> {
        if (!homeId) return undefined
        try {
            const res = await strapi.post(`/homes/${homeId}/request-avm`)
            return res.data
        } catch (e) {
            if (axios.isAxiosError(e)) {
                console.error(
                    `[RequestAvm] Error in route: ${this.route}`,
                    e.message
                )
            }
            return undefined
        }
    }

    async sendMessage(
        homeId: number,
        message: string
    ): Promise<boolean | undefined> {
        try {
            const res = await strapi.post(`/homes/${homeId}/send-message`, {
                message
            })
            return res.data
        } catch (e) {
            if (axios.isAxiosError(e)) {
                console.error(
                    `[SendMessage] Error in route: ${this.route}`,
                    e.message
                )
            }
            return undefined
        }
    }

    // async getRecentlySoldNear(
    //     homeId: number
    // ): Promise<IHomeRecentlySoldNear[] | undefined> {
    //     try {
    //         const res = await strapi.get(`/homes/${homeId}/recently-sold`)
    //         return res.data as IHomeRecentlySoldNear[]
    //     } catch (e) {
    //         if (axios.isAxiosError(e)) {
    //             console.error(`[RecentlySold] Error in route: ${this.route}`, e.message)
    //         }
    //         return undefined
    //     }
    // }

    async submitCashOfferForm(
        homeId: number,
        values: any
    ): Promise<IHome | undefined> {
        try {
            const res = await strapi.post(`/homes/${homeId}/submit-cash-offer-form`, {
                data: {...values},
            })
            return res.data as IHome
        } catch (e) {
            if (axios.isAxiosError(e)) {
                console.error(
                    `[SubmitCashOfferForm] Error in route: ${this.route}`,
                    e.message
                )
            }
            return undefined
        }
    }

    async submitSimpleSaleSurvey(
        homeId: number,
        values: any
    ): Promise<IHome | undefined> {
        try {
            const res = await strapi.post(
                `/homes/${homeId}/submit-simple-sale-survey`,
                {data: {...values}}
            )
            return res.data as IHome
        } catch (e) {
            if (axios.isAxiosError(e)) {
                console.error(
                    `[SubmitSimpleSaleSurvey] Error in route: ${this.route}`,
                    e.message
                )
            }
            return undefined
        }
    }

    async submitInteractiveForm(
        homeId: number,
        formId: ETaskFormTypes,
        values: any,
        taskId?: number
    ): Promise<IHome | undefined> {
        try {
            const query = taskId ? `?taskId=${taskId}` : ""
            const res = await strapi.post(
                `/homes/${homeId}/submit-${formId}-form${query}`,
                {
                    data: {...values},
                }
            )
            return res.data as IHome
        } catch (e) {
            if (axios.isAxiosError(e)) {
                console.error(
                    `[SubmitInteractiveForm] Error in route: ${this.route}`,
                    e.message
                )
            }
            return undefined
        }
    }

    async createHomeListingPaymentIntent(
        homeId: number,
        values: any
    ): Promise<{ clientSecret: string } | undefined | null> {
        try {
            const res = await strapi.post(
                `/homes/${homeId}/home-listing-payment-intent`,
                {
                    data: {...values},
                }
            )
            return res.data
        } catch (e) {
            if (axios.isAxiosError(e)) {
                console.error(
                    `[createHomeListingPaymentIntent] Error in route: ${this.route}`,
                    e.message
                )
            }
            return undefined
        }
    }

    async getPricingModel(
        homeId: number
    ): Promise<IHomePricingModel | undefined> {
        try {
            const res = await strapi.get(`/homes/${homeId}/home-pricing-model`)
            return res.data as IHomePricingModel
        } catch (e) {
            if (axios.isAxiosError(e)) {
                console.error(
                    `[GetPricingModel] Error in route: ${this.route}`,
                    e.message
                )
            }
            return undefined
        }
    }

    async getListing(homeId: number): Promise<IHomeListing | undefined> {
        try {
            const res = await strapi.get(`/homes/${homeId}/home-listing`)
            return res.data as IHomeListing
        } catch (e) {
            if (axios.isAxiosError(e)) {
                console.error(`[GetListing] Error in route: ${this.route}`, e.message)
            }
            return undefined
        }
    }
}

export const homeApi = new HomeApiSingleton(ModelRoutes.Homes, [], true, true)
