import {
  Alert,
  Button,
  Card,
  Center,
  Grid,
  Group,
  Paper,
  Stack,
  Text,
} from "@mantine/core"
import {
  AttomAvm,
  homeHooks,
  HomeSendMessageModal,
  HomeUtils,
  RecentlySoldListTable,
  ZillowAvm,
} from "features/homes"
import { Content } from "components/common"
import { useNavigate, useParams } from "react-router-dom"
import { ETaskFormTypes, taskHooks } from "features/tasks"
import { IconArrowRight } from "@tabler/icons"
import StockHome from "assets/stock-home.jpg"
import { useUserRoutes } from "routes"
import { ConciergeDetails } from "features/concierges"
import { offerHooks } from "features/offers"
import { TaskStepsCurrentStep, taskStepHooks } from "features/task-steps"
import { useMemo } from "react"
import { CircleCheck, Confetti, RotateThreeSixty } from "tabler-icons-react"
import { GoogleMap, StreetViewPanorama } from "@react-google-maps/api"

export function HomeDetails() {
  const { homeId } = useParams()
  const navigate = useNavigate()

  const { routesResolver } = useUserRoutes()

  const { data: home, isLoading: isLoadingHome } = homeHooks.useGetById(
    parseInt(homeId || ""),
    {
      populate: "*",
    }
  )
  const { mutateAsync: requestAvm, isLoading: isRequestingAvm } =
    homeHooks.useRequestAvm()
  const { mutateAsync: sendMessage } = homeHooks.useSendMessage()
  // const { isLoading: isLoadingRecentlySold, data: recentlySold } =
  //   homeHooks.useGetRecentlySold(parseInt(homeId || ""))
  const { isLoading: isLoadingSellScore, data: sellScore } =
    homeHooks.useGetSellScore(parseInt(homeId || ""))
  const {
    isLoading: isLoadingEstimatedMarketValue,
    data: estimatedMarketValue,
  } = homeHooks.useGetEstimatedMarketValue(parseInt(homeId || ""))

  const { data: tasks, isLoading: loadingTasks } = taskHooks.useGet(
    {
      filters: { home: parseInt(homeId || "") },
    },
    home?.data?.attributes?.sellStatus ? true : false
  )

  const { data: offers } = offerHooks.useGet(
    {
      filters: { homeId: parseInt(homeId || "") },
    },
    home?.data?.attributes?.sellStatus ? true : false
  )

  const { data: taskSteps } = taskStepHooks.useGet(
    {
      filters: {
        category: "sell",
        subCategory: home?.data?.attributes?.sellStatus,
      },
    },
    home?.data?.attributes?.sellStatus ? true : false
  )

  const sortedTaskSteps = useMemo(
    () =>
      taskSteps?.data.sort(
        (a, b) => (a.attributes.sequence || 0) - (b.attributes.sequence || 0)
      ),
    [taskSteps]
  )

  const currentStep = useMemo(
    () => getCurrentStep(),
    [taskSteps, tasks, loadingTasks]
  )

  const currentTask = useMemo(
    () => getCurrentTask(),
    [taskSteps, tasks, loadingTasks]
  )

  if (!home) return null

  function getCurrentStep() {
    if (!tasks || !sortedTaskSteps) return null

    // Get the index of the earliest step (step with lowest sequence) where any of its corresponding tasks are not completed
    let currentStep = sortedTaskSteps[0]
    for (let i = 0; i < sortedTaskSteps.length; i++) {
      const taskStep = sortedTaskSteps[i]
      const incomplete = tasks?.data.find(
        (t) =>
          t.attributes.stepId === taskStep.attributes.stepId &&
          !t.attributes.completedOn
      )

      if (incomplete) {
        currentStep = taskStep
        break
      }
    }

    return currentStep
  }

  function getCurrentTask() {
    if (!tasks || !currentStep) return null

    return tasks?.data
      ?.sort(
        (a, b) => (a.attributes.sequence ?? 0) - (b.attributes.sequence ?? 0)
      )
      .find(
        (task) =>
          task.attributes.stepId === currentStep?.attributes.stepId &&
          !task.attributes.completedOn
      )
  }

  function renderBody() {
    if (HomeUtils.isBeingSold(home?.data)) {
      return (
        <Stack mb={"xl"}>
          <Alert
            icon={<Confetti />}
            variant="light"
            color="blue"
            title="Congratulations! You are on the way to achieving your goals."
          >
            <Text>
              A special thank you for trusting us with your real estate needs.
              Please complete the tasks below to progress through the sales
              process.
            </Text>
          </Alert>
          <Grid grow>
            <Grid.Col sm={12} md={6}>
              <Stack
                h="100%"
                sx={{
                  position: "relative",
                  overflow: "hidden",
                  borderRadius: 5,
                }}
              >
                {home?.data.attributes.latitude &&
                home.data.attributes.longitude ? (
                  <GoogleMap
                    streetView={undefined}
                    mapContainerStyle={{
                      height: "100%",
                      width: "100%",
                    }}
                    center={{
                      lat: parseFloat(home.data.attributes.latitude),
                      lng: parseFloat(home.data.attributes.longitude),
                    }}
                    zoom={10}
                  >
                    <StreetViewPanorama
                      options={{
                        disableDefaultUI: true,
                        position: {
                          lat: parseFloat(home.data.attributes.latitude),
                          lng: parseFloat(home.data.attributes.longitude),
                        },
                        visible: true,
                      }}
                    />
                  </GoogleMap>
                ) : (
                  <img
                    height="100%"
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                    src={StockHome}
                    alt={home?.data.attributes.streetAddress}
                  />
                )}
              </Stack>
            </Grid.Col>
            <Grid.Col span={6}>
              <Grid>
                <Grid.Col span={12}>
                  <Paper withBorder p="sm">
                    <Stack>
                      <Center>
                        <Text fw={700} size={"xl"}>
                          Offers
                        </Text>
                      </Center>
                      <Center>
                        <Text fw={700} style={{ fontSize: 48 }}>
                          {offers?.data.length || "Pending"}
                        </Text>
                      </Center>
                      <Center>
                        <Button
                          onClick={() =>
                            navigate(
                              routesResolver.getInteractiveFormByHomeId(
                                parseInt(homeId || ""),
                                ETaskFormTypes.REVIEW_OFFERS,
                                currentTask?.attributes.formId ===
                                  ETaskFormTypes.REVIEW_OFFERS
                                  ? currentTask?.id
                                  : undefined
                              )
                            )
                          }
                          disabled={offers?.data.length === 0}
                        >
                          View Offers
                        </Button>
                      </Center>
                    </Stack>
                  </Paper>
                </Grid.Col>

                <Grid.Col span={12}>
                  <Card withBorder p={16}>
                    <Stack>
                      <Stack align="center">
                        <Text weight={600} size="lg">
                          Estimated Home Value
                        </Text>
                      </Stack>
                      {!HomeUtils.isBeingSold(home?.data) && (
                        <Stack align="center" mb={24}>
                          <Text size="sm">
                            Get our expert's opinion on your home's value today.
                          </Text>
                          <Button leftIcon={<RotateThreeSixty />}>
                            Request Valuation Estimate
                          </Button>
                        </Stack>
                      )}
                      <Grid w="100%">
                        <Grid.Col md={12} lg={6}>
                          <AttomAvm
                            loading={isLoadingHome}
                            attomAvm={home?.data.attributes.attomAvm}
                          />
                        </Grid.Col>
                        <Grid.Col md={12} lg={6}>
                          <ZillowAvm
                            loading={isLoadingHome}
                            zillowAvm={home?.data.attributes.zillowAvm}
                          />
                        </Grid.Col>
                      </Grid>
                      {!HomeUtils.isBeingSold(home?.data) && (
                        <Alert color="yellow">
                          <Text span>
                            The values above are generated by automated
                            valuation models, which come with a margin of error.
                            Click the Request Valuation Estimate button to get a
                            more precise, free home valuation from your local
                            expert today!
                          </Text>
                        </Alert>
                      )}
                    </Stack>
                  </Card>
                </Grid.Col>
              </Grid>
            </Grid.Col>
          </Grid>
          <Stack mt={24}>
            <TaskStepsCurrentStep
              home={home?.data}
              tasks={tasks?.data}
              loading={loadingTasks}
              currentStep={currentStep}
              currentTask={currentTask}
            />
          </Stack>
        </Stack>
      )
    } else {
      return (
        <>
          <Card withBorder p={16}>
            <Stack>
              <Stack align="center">
                <Text weight={600} size="lg">
                  Estimated Home Value
                </Text>
              </Stack>
              {!HomeUtils.isBeingSold(home?.data) && (
                <Stack align="center" mb={24}>
                  <Text size="sm">
                    Get our expert's opinion on your home's value today.
                  </Text>
                  <Button
                    disabled={home?.data.attributes.valuationRequested}
                    onClick={() => requestAvm(home?.data.id)}
                    leftIcon={
                      home?.data.attributes.valuationRequested ? (
                        <CircleCheck size={18} />
                      ) : (
                        <RotateThreeSixty size={18} />
                      )
                    }
                    loading={isRequestingAvm}
                  >
                    {home?.data.attributes.valuationRequested
                      ? "Valuation Requested"
                      : "Request Valuation Estimate"}
                  </Button>
                </Stack>
              )}
              <Grid w="100%">
                <Grid.Col md={12} lg={6}>
                  <AttomAvm
                    loading={isLoadingHome}
                    attomAvm={home?.data.attributes.attomAvm}
                  />
                </Grid.Col>
                <Grid.Col md={12} lg={6}>
                  <ZillowAvm
                    loading={isLoadingHome}
                    zillowAvm={home?.data.attributes.zillowAvm}
                  />
                </Grid.Col>
              </Grid>
              {!HomeUtils.isBeingSold(home?.data) && (
                <Alert color="yellow">
                  <Text mb={home?.data?.attributes?.concierge?.data ? 12 : 0}>
                    The values above are generated by automated valuation
                    models, which come with a margin of error. Click the Request
                    Valuation Estimate button to get a more precise, free home
                    valuation from your local expert today!
                  </Text>
                </Alert>
              )}
            </Stack>
          </Card>
          <Content.Section mt={24}>
            <Content.Header>
              <Content.Title>Recently Sold Near You</Content.Title>
            </Content.Header>
            <Content.Body>
              <RecentlySoldListTable
                disableFields={["daysOnMarket", "score"]}
                data={home?.data.attributes.recentSolds
                  ?.filter((home) => home.soldAmount > 0)
                  .sort(
                    (a, b) =>
                      new Date(b.soldDate).getTime() -
                      new Date(a.soldDate).getTime()
                  )
                  .slice(0, 3)}
                // loading={isLoadingRecentlySold}
              />
              <Group position={"right"} p={"lg"}>
                <Button
                  rightIcon={<IconArrowRight />}
                  onClick={() => {
                    navigate(
                      routesResolver.getHomeRecentlySoldNearYouByHomeId(
                        parseInt(homeId || "")
                      )
                    )
                  }}
                >
                  See More
                </Button>
              </Group>
            </Content.Body>
          </Content.Section>
        </>
      )
    }
  }

  return (
    <Content.Body>
      <Grid gutter="xl">
        <Grid.Col md={10} sm={12}>
          {renderBody()}
        </Grid.Col>
        <Grid.Col md={2} sm={12}>
          <Stack sx={{ position: "sticky", top: 12 }}>
            <Content.Section sx={{ border: "none", background: "transparent" }}>
              <Content.Header px={0} py={0}>
                <Content.Title p={0}>Your Concierge</Content.Title>
              </Content.Header>
              <Content.Body mt={12}>
                <ConciergeDetails
                  concierge={home?.data?.attributes?.concierge?.data}
                />
                {home?.data?.attributes?.concierge?.data && (
                  <HomeSendMessageModal.Button
                    onSubmitForm={(values: any) =>
                      sendMessage({
                        ...values,
                        homeId: home?.data.id,
                      })
                    }
                  />
                )}
              </Content.Body>
            </Content.Section>
          </Stack>

          {/*{HomeUtils.isBeingSold(home?.data) && (*/}
          {/*  <Content.Section*/}
          {/*    sx={{*/}
          {/*      border: "none",*/}
          {/*      background: "transparent",*/}
          {/*      marginTop: "32px",*/}
          {/*    }}*/}
          {/*  >*/}
          {/*    <Content.Header px={0} py={0}>*/}
          {/*      <Content.Title p={0}>Selling Timeline</Content.Title>*/}
          {/*    </Content.Header>*/}
          {/*    <Content.Body mt={12}>*/}
          {/*      <ClosingStepsTimeline*/}
          {/*        home={home?.data}*/}
          {/*        tasks={tasks?.data}*/}
          {/*        taskSteps={taskSteps?.data}*/}
          {/*      />*/}
          {/*    </Content.Body>*/}
          {/*  </Content.Section>*/}
          {/*)}*/}
        </Grid.Col>
      </Grid>
    </Content.Body>
  )
}
