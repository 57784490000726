import { ETaskFormTypes } from "features/tasks"
import { RouteSegments } from "./segments"
import { BaseRoutesResolver } from "utils"

export class RoutesResolver extends BaseRoutesResolver {
  constructor(baseRoute = "") {
    super(baseRoute)
  }

  get dashboard() {
    return this.concatRoutes([RouteSegments.Dashboard])
  }

  get users() {
    return this.concatRoutes([RouteSegments.Users])
  }

  get forgotPassword() {
    return this.concatRoutes(
      [RouteSegments.Auth, RouteSegments.ForgotPassword],
      true
    )
  }

  get login() {
    return this.concatRoutes([RouteSegments.Auth, RouteSegments.Login], true)
  }

  /* Generated Routes Resolver */

  get taskStepCreate() {
    return this.concatRoutesToBase([
      RouteSegments.TaskSteps,
      RouteSegments.Create,
    ])
  }

  getTaskStepById(taskStepId: number) {
    return this.concatRoutes([this.taskSteps, taskStepId])
  }

  get taskSteps() {
    return this.concatRoutes([RouteSegments.TaskSteps], true)
  }

  get documentFolderCreate() {
    return this.concatRoutesToBase([
      RouteSegments.DocumentFolders,
      RouteSegments.Create,
    ])
  }

  getDocumentFolderById(documentFolderId: number) {
    return this.concatRoutes([this.documentFolders, documentFolderId])
  }

  get documentFolders() {
    return this.concatRoutes([RouteSegments.DocumentFolders], true)
  }

  get documentCreate() {
    return this.concatRoutesToBase([
      RouteSegments.Documents,
      RouteSegments.Create,
    ])
  }

  getDocumentById(documentId: number) {
    return this.concatRoutes([this.documents, documentId])
  }

  get documents() {
    return this.concatRoutes([RouteSegments.Documents], true)
  }

  get conciergeCreate() {
    return this.concatRoutesToBase([
      RouteSegments.Concierges,
      RouteSegments.Create,
    ])
  }

  getConciergeById(conciergeId: number) {
    return this.concatRoutes([this.concierges, conciergeId])
  }

  get concierges() {
    return this.concatRoutes([RouteSegments.Concierges], true)
  }

  get taskCreate() {
    return this.concatRoutesToBase([RouteSegments.Tasks, RouteSegments.Create])
  }

  getTaskById(taskId: number) {
    return this.concatRoutes([this.tasks, taskId])
  }

  get tasks() {
    return this.concatRoutes([RouteSegments.Tasks], true)
  }

  get homeCreate() {
    return this.concatRoutes([RouteSegments.Homes, RouteSegments.Create], true)
  }

  getHomeById(homeId: number | string) {
    return this.concatRoutes([this.homes, homeId])
  }

  getHomeServicesById(homeId: number | string) {
    return this.concatRoutes(
      [RouteSegments.Homes, homeId, RouteSegments.Services],
      true
    )
  }

  getHomeSettingsById(homeId: number | string) {
    return this.concatRoutes(
      [RouteSegments.Homes, homeId, RouteSegments.Details],
      true
    )
  }

  getHomeDetailsById(homeId: number | string) {
    return this.concatRoutes(
      [RouteSegments.Homes, homeId, RouteSegments.Today],
      true
    )
  }

  getInteractiveFormByHomeId(
    homeId: number | string,
    formId: ETaskFormTypes,
    taskId?: number | string | null
  ) {
    const query = taskId ? `?taskId=${taskId}` : ""
    return `${this.concatRoutes(
      [RouteSegments.Homes, homeId, RouteSegments.Forms, formId],
      true
    )}${query}`
  }

  getHomeDocumentsByHomeId(homeId: number | string) {
    return this.concatRoutes(
      [RouteSegments.Homes, homeId, RouteSegments.Documents],
      true
    )
  }

  getHomeRecentlySoldNearYouByHomeId(homeId: number | string) {
    return this.concatRoutes(
      [RouteSegments.Homes, homeId, RouteSegments.RecentlySoldNearYou],
      true
    )
  }

  get homes() {
    return this.concatRoutes([RouteSegments.Homes], true)
  }

  getEmbeddedDocumentByHomeAndTaskId(
    homeId: number | string,
    taskId: number | string
  ) {
    return this.concatRoutes(
      [
        RouteSegments.Homes,
        homeId,
        RouteSegments.Tasks,
        taskId,
        RouteSegments.EmbeddedDocument,
      ],
      true
    )
  }

  getHomeListingPaymentConfirmationByHomeId(homeId: number | string, taskId?: number | string | null) {
    const route = this.concatRoutes(
      [
        RouteSegments.Homes,
        homeId,
        RouteSegments.HomeListingPaymentConfirmation,
      ],
      true
    )

    return taskId ? `${route}?taskId=${taskId}` : route
  }

  getEmbeddedDocumentByHomeAndOfferId(
    homeId: number | string,
    offerId: number | string,
    taskId?: number | string
  ) {
    const query = taskId ? `?taskId=${taskId}` : ""
    return `${this.concatRoutes(
      [
        RouteSegments.Homes,
        homeId,
        RouteSegments.Offers,
        offerId,
        RouteSegments.EmbeddedDocument,
      ],
      true
    )}${query}`
  }
}
