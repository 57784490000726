import {ROLES} from "settings"
import {authHooks} from "features/auth"

export interface PermissionsReturn {
    isAppAdmin: boolean
    isAuthenticated: boolean
    isConcierge: boolean
    isRole: (role: ROLES) => boolean,
    ownsEntity: (resourceOrgId?: number) => boolean
    isAppAdminOrOwnsEntity: (resourceOrgId?: number) => boolean
}

export function usePermissions(): PermissionsReturn {
    const {data: me} = authHooks.useMe()

    function isRole(role: ROLES) {
        return me?.role?.type === role
    }


    const isAppAdmin = isRole(ROLES.APPLICATION_ADMINISTRATOR)
    const isAuthenticated = isRole(ROLES.AUTHENTICATED)
    const isConcierge = isRole(ROLES.CONCIERGE)

    function ownsEntity(entityUserId?: number) {
        if (entityUserId && me?.id) {
            return me.id === entityUserId
        } else {
            return false
        }
    }

    function isAppAdminOrOwnsEntity(entityOrgId?: number) {
        return isAppAdmin || ownsEntity(entityOrgId)
    }


    return {
        isAuthenticated,
        isConcierge,
        isAppAdmin,
        ownsEntity,
        isAppAdminOrOwnsEntity,
        isRole,
    }
}
