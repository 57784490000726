import { useNavigate } from "react-router-dom"
import { AddressUtils, Content, GooglePlaces, Page } from "components/common"
import { DefaultPageWrapper } from "layouts"
import { useUserRoutes } from "routes"
import { useForm } from "@mantine/form"
import {
  homeHooks,
  HomeUtils,
  IAddHomeForm,
  ICoOwner,
  HomeCoOwnersListForm,
} from "features/homes"
import { useCurrentUser } from "hooks"
import { AddressComponent } from "@google/maps"
import { Button, Checkbox, Divider, Group, Stack, Text } from "@mantine/core"
import { ArrowRight } from "tabler-icons-react"

export function HomeCreatePage() {
  const navigate = useNavigate()
  const { user } = useCurrentUser()
  const { routesResolver } = useUserRoutes()
  const { mutateAsync: createHome, isLoading: isCreatingHome } =
    homeHooks.useCreate()

  const {data: homes} = homeHooks.useGet({
      filters: {
          user: user?.id
      },
      sort: "createdAt:asc",
      populate: "*"
  })

  const form = useForm<IAddHomeForm>({
    validate: AddressUtils.getValidationSchema(),
    validateInputOnChange: true,
  })

  const coOwnersForm = useForm<{ items: ICoOwner[] }>({
    initialValues: {
      items: [HomeUtils.transformCoOwnerInitialValues()],
    },
    validate: {
      items: HomeUtils.getCoOwnersValidationSchema(),
    },
    validateInputOnChange: true,
  })

  const getStreetAddressInitial = (
    addressComponents: AddressComponent<any>[] | null
  ) => {
    if (addressComponents) {
      const street = addressComponents?.find((c) =>
        c.types.includes("route")
      )?.long_name
      const number = addressComponents?.find((c) =>
        c.types.includes("street_number")
      )?.long_name
      return `${number} ${street}`
    } else return ""
  }

  function onGooglePlacesChange(
    addressComponents: AddressComponent<any>[] | null
  ) {
    form.setValues({
      streetAddress: getStreetAddressInitial(addressComponents),
      city:
        addressComponents?.find((c) => c.types.includes("locality"))
          ?.long_name || "",
      state:
        addressComponents?.find((c) =>
          c.types.includes("administrative_area_level_1")
        )?.short_name || "",
      zip:
        addressComponents?.find((c) => c.types.includes("postal_code"))
          ?.short_name || "",
    })
  }

  async function onSubmitHome() {
    if (!form.validate().hasErrors) {
      const coOwnerPayload = coOwnersForm.values.items
      if (
        coOwnersForm &&
        coOwnersForm.values.items &&
        coOwnersForm.values.items.length > 0
      ) {
        for (let i = 0; i < coOwnerPayload.length; i++) {
          const coOwner = coOwnerPayload[i]
          coOwner.fullName = `${coOwner.firstName} ${coOwner.lastName}`
          coOwnerPayload[i] = coOwner
        }
      }
      const home = await createHome({
        ...form.values,
        coowners: coOwnerPayload,
        user: user?.id,
      })
      if (home?.data) {
        navigate(routesResolver.getHomeById(home.data.id))
      }
    }
  }

  return (
    <DefaultPageWrapper>
      <Page.Header>
        {homes?.data && homes.data.length > 0 ? (<Page.Title
          link={{
            label: "Back to Homes",
            to: routesResolver.homes,
          }}
          title="Add Your Primary Residence"
        />) : null}
      </Page.Header>
      <Page.Body>
        <Stack>
          <Stack spacing={6}>
            <Text weight={600}>Find Your Home</Text>
            <GooglePlaces onChange={onGooglePlacesChange} />
          </Stack>
          <Content.Section>
            <Content.Body p={16}>
              <Text weight={600}>Street Address</Text>
              {form.values.streetAddress ? (
                <Text size="sm">{form.values.streetAddress}</Text>
              ) : (
                <Text italic color="dimmed">
                  No address selected
                </Text>
              )}
              {form.values.city && form.values.state && (
                <Text size="sm">
                  {form.values.city}, {form.values.state}
                </Text>
              )}
              {form.values.zip && <Text size="sm">{form.values.zip}</Text>}
              <Divider my={12} />
              <Checkbox
                label="Do you share ownership of this home?"
                {...form.getInputProps("hasCoOwners", { type: "checkbox" })}
              />
              {form.values.hasCoOwners && (
                <Stack mt={12}>
                  <HomeCoOwnersListForm form={coOwnersForm} />
                </Stack>
              )}
              <Group mt={12} position="right" w="100%">
                <Button
                  onClick={onSubmitHome}
                  loading={isCreatingHome}
                  disabled={
                    !form.isValid() ||
                    (form.values.hasCoOwners && !coOwnersForm.isValid())
                  }
                  rightIcon={<ArrowRight size={18} />}
                >
                  Save My Home
                </Button>
              </Group>
            </Content.Body>
          </Content.Section>
        </Stack>
      </Page.Body>
    </DefaultPageWrapper>
  )
}
