import {Alert, Avatar, Button, Center, Group, Stack, Text} from "@mantine/core"
import {IConcierge} from "features/concierges/types"
import {IStrapiEntity} from "services/strapi"
import {Send} from "tabler-icons-react";

interface Props {
    concierge?: IStrapiEntity<IConcierge>
    loading?: boolean
}

export function ConciergeDetails({concierge}: Props) {
    if (!concierge) {
        return (
            <Alert color="violet" title="Under Review">
                Your home is currently under review. When the review is finished, we
                will assign you a Concierge.
            </Alert>
        )
    }

    function formatPhoneNumber(phoneNumber: string) {
        const cleaned = ("" + phoneNumber).replace(/\D/g, "")
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
        if (match) {
            return "(" + match[1] + ") " + match[2] + "-" + match[3]
        }
        return null
    }

    function callConcierge() {
        window.open(`tel:${concierge?.attributes.phoneNumber}`)
    }

    function emailConcierge() {
        window.open(`mailto:${concierge?.attributes.email}`)
    }

    return (
        <Stack spacing={3}>
            <Center>
                <Avatar
                    radius="xl"
                    size={"xl"}
                    src={concierge.attributes.photoUrl}
                />
            </Center>
            <Text size="sm" weight={600} mt={6}>
                {concierge.attributes.firstName} {concierge.attributes.lastName}
            </Text>
            {concierge.attributes.phoneNumber && (
                <Group
                    noWrap
                    spacing={4}
                    onClick={callConcierge}
                    style={{cursor: "pointer"}}
                >
                    <Text size="sm">
                        {formatPhoneNumber(concierge.attributes.phoneNumber)}
                    </Text>
                </Group>
            )}
            {concierge.attributes.email && (
                <Group
                    noWrap
                    spacing={4}
                    onClick={emailConcierge}
                    style={{cursor: "pointer"}}
                >
                    <Text size="sm">{concierge.attributes.email}</Text>
                </Group>
            )}
        </Stack>
    )
}
