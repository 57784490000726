import { ITask } from "features/tasks"

import { Button, Text } from "@mantine/core"
import { IconCalendar } from "@tabler/icons"
import { IStrapiEntity } from "services/strapi"
import { IHome } from "features/homes"
import { InlineWidget, useCalendlyEventListener } from "react-calendly"
import { useModals } from "@mantine/modals"
import { authHooks } from "features/auth"

interface Props {
  calendlyUrl: string
  task?: IStrapiEntity<ITask>
  onTaskComplete?: () => void
}

export function TaskCalendlyButton({ task, calendlyUrl, onTaskComplete }: Props) {
  const modals = useModals()
  const {data: me} = authHooks.useMe()

  useCalendlyEventListener({
    onEventScheduled: (e) => {
      e.preventDefault()
      if (onTaskComplete) onTaskComplete()
    },
  })

  if (!task) return null

  function onClick() {
    modals.openModal({
      withCloseButton: true,
      closeOnClickOutside: true,
      closeOnEscape: true,
      centered: true,
      overflow: "outside",
      size: 1200,
      title: <Text size={"xl"}>Schedule a Meeting with Behoused</Text>,
      children: (
        <InlineWidget
          url={calendlyUrl}
          prefill={{
            email: me?.email,
            firstName: me?.firstName,
            lastName: me?.lastName,
            name: `${me?.firstName} ${me?.lastName}`,
            smsReminderNumber:
              me?.phoneNumber,
          }}
        />
      ),
    })
  }

  return (
    <Button leftIcon={<IconCalendar />} onClick={onClick}>
      Schedule via Calendly
    </Button>
  )
}
