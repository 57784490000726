import { NoData } from "components/common"
import { DefaultPageWrapper } from "layouts"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import {
  Button,
  Center,
  Group,
  Loader,
  Stack,
  Text,
  Title,
} from "@mantine/core"
import { useEffect, useState } from "react"
import { ETaskFormTypes, taskHooks } from "features/tasks"
import { ReactComponent as FestivitiesSVG } from "assets/undraw/undraw_festivities.svg"
import {ReactComponent as NoDataSVG} from "assets/undraw/undraw_empty_street.svg"
import { useUserRoutes } from "routes"
import { useStripe } from "@stripe/react-stripe-js"

export function HomeListingPaymentConfirmation() {
  const { homeId } = useParams()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const taskId = searchParams.get("taskId")
  const { routesResolver } = useUserRoutes()
  const stripe = useStripe()
  const [paymentStatus, setPaymentStatus] = useState<'loading' | 'processing' | 'failed' | 'success'>("loading")

  const { data: task, isLoading: isLoadingTask } = taskHooks.useGetById(
    parseInt(taskId || ""),
    {}
  )

  const { mutateAsync: updateTask, isLoading: updatingTask } =
    taskHooks.useUpdate()

  async function markTaskAsDone(taskId: number) {
    await updateTask({ id: taskId, values: { completedOn: new Date() } })
  }

  useEffect(() => {
    if (!stripe) {
      return
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    )

    if (!clientSecret) {
      return
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      if (paymentIntent) {
        switch (paymentIntent.status) {
          case "succeeded":
            
            setPaymentStatus('success')
            break
          case "processing":
            setPaymentStatus('processing')
            break
          case "requires_payment_method":
            console.log(paymentIntent)
            setPaymentStatus('failed')
            break
          default:
            setPaymentStatus('failed')
            break
        }
      }
    })
  }, [stripe])

  // Automatically mark related signing task as complete
  useEffect(() => {
    if (paymentStatus === 'success' && task && task?.data && !task?.data.attributes.completedOn) {
      markTaskAsDone(task.data.id)
    }
  }, [task, isLoadingTask, paymentStatus])

  const renderContent = () => {
    if (isLoadingTask || updatingTask || !stripe || paymentStatus === "loading") {
      return (
        <Center h={"100%"} w={"100%"}>
          <Group noWrap>
            <Loader size="sm" />
            <Text>Finishing up payment, please do not leave the page...</Text>
          </Group>
        </Center>
      )
    }

    if (!task || !homeId) {
      return (
        <Center h={"100%"} w={"100%"}>
          <NoData title="No task associated to a home listing payment was found, please contact your concierge or go back to your Home's page and try again." />
        </Center>
      )
    }

    if (paymentStatus === 'processing') {
      return (
        <Center h={"100%"} w={"100%"}>
          <Stack spacing={0} py={24} align="center">
            <NoDataSVG width={600} height={200} />
            <Stack
              spacing={0}
              mt={18}
              style={{
                maxWidth: 600,
                textAlign: "center",
              }}
            >
              <Text
                size={24}
                gradient={{
                  from: "red",
                  to: "orange",
                  deg: 45,
                }}
                weight={600}
                variant="gradient"
              >
                Your payment is on hold.
              </Text>
              <Text size={18} mt={6} color="dimmed" mb={"lg"}>
                Please contact your concierge.
              </Text>
            </Stack>
          </Stack>
        </Center>
      )
    }

    if(paymentStatus === 'failed' || paymentStatus !== 'success') {
      return (
        <Center h={"100%"} w={"100%"}>
          <Stack spacing={0} py={24} align="center">
            <NoData title="Your payment was not successful, please try again" />
            <Button
              onClick={() => navigate(routesResolver.getInteractiveFormByHomeId(
                homeId,
                ETaskFormTypes.HOME_LISTING_PAYMENT,
                taskId
              ))}
            >
              Return to Payment Page
            </Button>
          </Stack>
        </Center>
      )
    }

    // Only show this component if payment was successful
    return (
      <Center h={"100%"} w={"100%"}>
        <Stack spacing={0} py={24} align="center">
          <FestivitiesSVG width={600} height={200} />
          <Stack
            spacing={0}
            mt={18}
            style={{
              maxWidth: 600,
              textAlign: "center",
            }}
          >
            <Text
              size={24}
              gradient={{
                from: "red",
                to: "orange",
                deg: 45,
              }}
              weight={600}
              variant="gradient"
            >
              Thank you for your payment! Your home listing setup is now in
              motion. Here's what happens next:
            </Text>
            <Stack spacing={"lg"} mt={"lg"} mb={"lg"}>
              <Stack spacing={0}>
                <Title size={"lg"}>Professional Home Photos</Title>
                <Text color="dimmed" size="md" span>
                  Our team will contact you shortly to schedule a convenient
                  time for the photo session.
                </Text>
              </Stack>
              <Stack spacing={0}>
                <Title size={"lg"}>Custom For Sale Sign</Title>
                <Text color="dimmed" size="md" span>
                  Your personalized for sale sign is being created and will be
                  delivered to your home soon.
                </Text>
              </Stack>
              <Stack spacing={0}>
                <Title size={"lg"}>Secure Key Lockbox</Title>
                <Text color="dimmed" size="md" span>
                  We will also arrange the delivery of your key lockbox to
                  ensure your home is ready for showings.
                </Text>
              </Stack>
            </Stack>
            <Text size={18} mt={6} color="dimmed" mb={"lg"}>
              Your concierge will be notified and will reach out to you shortly.
              You may now leave the page.
            </Text>
            <Button
              onClick={() => navigate(routesResolver.getHomeById(homeId || ""))}
            >
              Return to Dashboard
            </Button>
          </Stack>
        </Stack>
      </Center>
    )
  }

  return (
    <DefaultPageWrapper>
      {renderContent()}
    </DefaultPageWrapper>
  )
}
