import { useSearchParams } from "react-router-dom"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import { HomeListingPaymentConfirmation } from "./home.home-listing-payment-confirmation"

export function HomeListingPaymentConfirmationWrapper() {
  const [searchParams] = useSearchParams()
  const stripePromise = loadStripe(
      process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || ""
    )

  return (
      <Elements 
        options={{
          clientSecret: searchParams.get("payment_intent_client_secret") || "",
        }}
        stripe={stripePromise}>
          <HomeListingPaymentConfirmation />
      </Elements>
  )
}
